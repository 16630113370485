import { api } from "api";
import moment from "moment";
import { icons } from "icons";
import PropTypes from "prop-types";
import { Async } from "react-select";
import React, { Component } from "react";
import DatePicker from "Utils/DatePicker";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { RenderNumero, RenderMoneda } from "Utils/renderField/renderTableField";

function formatoAvatar(cell, row) {
    return (
        <div>
            {row.imagen ? (
                <img
                    className="imagen-sm-50"
                    src={row.imagen}
                    alt=""
                    style={{ width: "50px", height: "50px" }}
                />
            ) : (
                <img
                    className="imagen-sm-50"
                    src={icons.img_default}
                    alt=""
                    style={{ width: "50px", height: "50px" }}
                />
            )}
        </div>
    );
}

let lista_usuarios = [];

class ProductosMasVendidos extends Component {
    static propTypes = {
        // loader: PropTypes.bool.isRequired,
        data: PropTypes.object.isRequired,
        fecha_inicial: PropTypes.string.isRequired,
        fecha_final: PropTypes.string.isRequired,
    };
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getReporteProdMasVendidos(this.props.page);
    }

    render() {
        //  state
        const {
            loader,
            data,
            fecha_inicial,
            fecha_final,
            usuarios_sucursal,
            id_usuario,
            estado_descarga,
        } = this.props;
        //  bind
        const { idUsuarioChange, descargarListado } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.onSortChange = this.props.sortChange;
        options.page = this.props.page;
        options.onPageChange = this.props.getReporteProdMasVendidos;

        const getUsuarios = (search) => {
            return api
                .get("usuarios/lista_all", { search })
                .catch(() => {})
                .then((data) => {
                    const options = data.map((item) => ({
                        value: item.id,
                        label: item.first_name,
                    }));
                    return { options };
                });
        };

        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                <div style={{ flex: "1" }}>
                                    <h3>
                                        <b>Historial de productos</b>
                                    </h3>
                                </div>
                                <div
                                    className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                    style={{ flex: "3" }}
                                >
                                    <div>
                                        <label>Fecha Inicio</label>
                                        <DatePicker
                                            value={fecha_inicial}
                                            maxDate={new Date()}
                                            onChange={(date) => {
                                                this.props.setFecha(
                                                    "Inicial",
                                                    date
                                                );
                                            }}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div>
                                        <label>Fecha Fin</label>
                                        <DatePicker
                                            value={fecha_final}
                                            minDate={
                                                new Date(
                                                    moment(
                                                        fecha_inicial,
                                                        "YYYY-MM-DD"
                                                    )
                                                )
                                            }
                                            onChange={(date) => {
                                                this.props.setFecha(
                                                    "Final",
                                                    date
                                                );
                                            }}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div style={{ flex: "0.5" }}>
                                        <label>Usuario</label>
                                        <div>
                                            <Async
                                                className="form-control"
                                                valueKey="value"
                                                labelKey="label"
                                                searchPromptText="Escriba para buscar"
                                                loadOptions={getUsuarios}
                                                placeholder={"Usuario"}
                                                onChange={(e) => {
                                                    idUsuarioChange(
                                                        e ? e.value : ""
                                                    );
                                                }}
                                                value={id_usuario}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* LISTADO */}
                            <hr />
                            <div className="grid-titulo">
                                <Toolbar
                                    titulo=""
                                    buscar={this.props.buscar}
                                    search={this.props.search}
                                    estado_descarga={estado_descarga}
                                    descarga_excel={descargarListado}
                                />
                            </div>
                            <LoadMask loading={loader} dark blur>
                                {/* <div className="d-flex flex-row w-100 p-3">
                                    <button
                                        className="btn btn-secondary mb-sm-10 align-self-center"
                                        disabled={estado_descarga}
                                        onClick={() => {
                                            descargarListado();
                                        }}
                                    >
                                        <em
                                            className={`fa ${
                                                estado_descarga
                                                    ? "fa-spinner fa-pulse"
                                                    : "fa-download"
                                            }`}
                                        ></em>
                                        &nbsp;Descargar Listado
                                    </button>
                                </div> */}
                                <BootstrapTable
                                    data={loader ? [] : data.results}
                                    remote
                                    pagination
                                    hover
                                    fetchInfo={{ dataTotalSize: data.count }}
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        dataField="img_cropped"
                                        dataFormat={formatoAvatar}
                                        width="80px"
                                    ></TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataFormat={(cell) =>
                                            cell ? cell : ""
                                        }
                                        dataField="codigo_barras"
                                        dataSort={true}
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        width="100px"
                                    >
                                        Código
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        isKey
                                        dataField="nombre"
                                        dataSort
                                        dStyle={BreakLine}
                                        thStyle={BreakLine}
                                    >
                                        Nombre
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="marca"
                                        dataFormat={(cell, row) => {
                                            return `${cell ? cell : "---"}`;
                                        }}
                                    >
                                        Marca
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="presentacion">
                                        Presentacion
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="cantidad"
                                        dataAlign="right"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderNumero monto={cell} />
                                            );
                                        }}
                                    >
                                        Vendidos en total
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="precio"
                                        dataAlign="right"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderMoneda monto={row.precio_producto} />
                                            );
                                        }}
                                    >
                                        Precio del producto
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="total_ventas"
                                        dataAlign="right"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderMoneda monto={cell} />
                                            );
                                        }}
                                        dataSort={ true }
                                        dStyle={BreakLine}
                                        thStyle={BreakLine}
                                    >
                                        Total recaudación
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductosMasVendidos;

import React, { Component } from "react";
import { api } from "api";
import PropTypes from "prop-types";
import Select, { Async } from "react-select";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { activeFormatter } from "Utils/Acciones/Acciones";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-select/dist/react-select.css";
import moment from "moment";
import DatePicker from "Utils/DatePicker";

const tipos_movimiento = [
    { value: 20, label: "Compra" },
    { value: 50, label: "Baja" },
    { value: 60, label: "Reajuste" },
    { value: 120, label: "Oden de compra"}
];

// CONSTANTES PARAL LOS SELECTS ASINCRONOS
let lista_usuarios = [];
let lista_proveedores = [];

function usuarioFormatter(cell, row) {
    return (
        <div>
            <span>{cell.first_name}</span>
        </div>
    );
}

function proveedorFormatter(cell, row) {
    return <div>{cell ? <span>{cell.nombre}</span> : <span>-----</span>}</div>;
}

class ReporteBodega extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getReporteBodega(this.props.page);
    }

    render() {
        //  state
        const {
            loader,
            data,
            fecha_inicial,
            fecha_final,
            id_usuario,
            id_proveedor,
            tipo_movimiento,
            estado_descarga,
            conf_habilitar_no_factura
        } = this.props;

        //  bind
        const {
            idUsuarioChange,
            idProveedorChange,
            tipoMovimientoChange,
            descargarListado,
        } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.onSortChange = this.props.sortChange;
        options.page = this.props.page;
        options.onPageChange = this.props.getReporteBodega;

        const getUsuarios = (search) => {
            return api
                .get("usuarios/lista_all", { search })
                .catch(() => {})
                .then((data) => {
                    const options = data.map((item) => ({
                        value: item.id,
                        label: item.first_name,
                    }));
                    return { options };
                });
        };

        const getProveedores = (search) => {
            return api
                .get("proveedores", { search })
                .catch(() => {})
                .then((data) => {
                    data.results.forEach((item) => {
                        if (!_.find(lista_proveedores, { id: item.id }))
                            lista_proveedores.push(item);
                    });
                    return { options: lista_proveedores };
                });
        };

        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                <div style={{ flex: "1" }}>
                                    <h3>
                                        <b>Bodega</b>
                                    </h3>
                                </div>
                                <div
                                    className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                    style={{ flex: "3" }}
                                >
                                    <div style={{ flex: "1" }}>
                                        <label>Fecha Inicio</label>
                                        <DatePicker
                                            value={fecha_inicial}
                                            maxDate={new Date()}
                                            onChange={(date) => {
                                                this.props.setFecha(
                                                    "Inicial",
                                                    date
                                                );
                                            }}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div style={{ flex: "1" }}>
                                        <label>Fecha Fin</label>
                                        <DatePicker
                                            value={fecha_final}
                                            minDate={
                                                new Date(
                                                    moment(
                                                        fecha_inicial,
                                                        "YYYY-MM-DD"
                                                    )
                                                )
                                            }
                                            onChange={(date) => {
                                                this.props.setFecha(
                                                    "Final",
                                                    date
                                                );
                                            }}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div style={{ flex: "1" }}>
                                        <label>Tipo</label>
                                        <div>
                                            <Select
                                                isClearable
                                                placeholder="Seleccionar"
                                                className="form-control"
                                                options={tipos_movimiento}
                                                valueKey="value"
                                                labelKey="label"
                                                value={tipo_movimiento}
                                                onChange={(e) => {
                                                    tipoMovimientoChange(
                                                        e ? e.value : ""
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="d-flex mt1 flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                    style={{ flex: "3" }}
                                >
                                    <div style={{ flex: "0.5" }}>
                                        <label>Usuario</label>
                                        <div>
                                            <Async
                                                className="form-control"
                                                valueKey="value"
                                                labelKey="label"
                                                searchPromptText="Escriba para buscar"
                                                loadOptions={getUsuarios}
                                                placeholder={"Usuario"}
                                                onChange={(e) => {
                                                    idUsuarioChange(
                                                        e ? e.value : ""
                                                    );
                                                }}
                                                value={id_usuario}
                                            />
                                        </div>
                                    </div>
                                    &nbsp; &nbsp;
                                    <div style={{ flex: "0.5" }}>
                                        {tipo_movimiento === 20 && (
                                            <div>
                                                <label>Proveedor</label>
                                                <div>
                                                    <Async
                                                        className="form-control"
                                                        labelKey="nombre"
                                                        valueKey="id"
                                                        searchPromptText="Escriba para buscar"
                                                        loadOptions={
                                                            getProveedores
                                                        }
                                                        placeholder={
                                                            "Proveedor"
                                                        }
                                                        onChange={(e) => {
                                                            idProveedorChange(
                                                                e ? e.id : ""
                                                            );
                                                        }}
                                                        value={id_proveedor}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <LoadMask loading={loader} dark blur>
                                {/* <div className="content-total-reports w-100 mt-10">
                                    <h3 className="padding-10 margin-0"><b>Total</b></h3>
                                    <h3 className="padding-10 margin-0"><b><RenderMoneda monto={data.total} /></b></h3>
                                </div> */}
                                {/* <div className="col-12">
                                    <strong>Total: </strong>
                                    <RenderMoneda monto={data.total}/>
                                </div> */}
                                <div className="d-flex flex-row w-100 p-3">
                                    <button
                                        className="btn btn-secondary mb-sm-10 align-self-center"
                                        disabled={estado_descarga}
                                        onClick={() => {
                                            descargarListado();
                                        }}
                                    >
                                        <em
                                            className={`fa ${
                                                estado_descarga
                                                    ? "fa-spinner fa-pulse"
                                                    : "fa-download"
                                            }`}
                                        ></em>
                                        &nbsp;Descargar Listado
                                    </button>
                                </div>
                                <BootstrapTable
                                    data={loader ? [] : data.results}
                                    remote
                                    pagination
                                    hover
                                    fetchInfo={{ dataTotalSize: data.count }}
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        dataField="creado"
                                        dataFormat={(cell) => (
                                            <RenderDateTime fecha={cell} />
                                        )}
                                        dataSort
                                    >
                                        Fecha
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="tipo_movimiento">
                                        Tipo
                                    </TableHeaderColumn>
                                    {
                                        conf_habilitar_no_factura && (
                                            <TableHeaderColumn
                                                dataField="no_factura"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                            >
                                                No. Factura
                                            </TableHeaderColumn>
                                        )
                                    }
                                    <TableHeaderColumn
                                        dataField="concepto"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                    >
                                        Concepto
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="usuario"
                                        dataFormat={usuarioFormatter}
                                    >
                                        Usuario
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="cliente_proveedor"
                                        dataFormat={proveedorFormatter}
                                    >
                                        Proveedor
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="id"
                                        isKey
                                        dataAlign="center"
                                        dataFormat={(cell, row) => {
                                            if (row.gasto_fijo === null)
                                                return activeFormatter({
                                                    ver: "/reporte_bodega",
                                                })(cell, row);
                                        }}
                                    >
                                        Acciones
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ReporteBodega.propTypes = {
    // data: PropTypes.object.isRequired,
    // fecha_inicial: PropTypes.string.isRequired,
    // fecha_final: PropTypes.string.isRequired,
};

export default ReporteBodega;
